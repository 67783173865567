import * as React from "react";

export function IconSparkles(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={16}
      height={16}
      {...props}
    >
      <path d="M5.5,0.052l1.101,3.307c0.084,0.251 0.225,0.48 0.413,0.668c0.188,0.187 0.418,0.328 0.67,0.412l3.316,1.098l-3.316,1.098c-0.252,0.083 -0.482,0.224 -0.67,0.412c-0.188,0.187 -0.329,0.416 -0.413,0.668l-1.101,3.307l-1.101,-3.307c-0.084,-0.252 -0.225,-0.481 -0.413,-0.668c-0.188,-0.188 -0.418,-0.329 -0.67,-0.412l-3.316,-1.098l3.316,-1.098c0.252,-0.084 0.482,-0.225 0.67,-0.412c0.188,-0.188 0.329,-0.417 0.413,-0.668l1.101,-3.307Z" />
      <path d="M12.5,9.019l0.7,2.104c0.054,0.161 0.144,0.306 0.264,0.426c0.119,0.119 0.265,0.209 0.426,0.262l2.11,0.699l-2.11,0.698c-0.161,0.053 -0.307,0.143 -0.426,0.262c-0.12,0.12 -0.21,0.265 -0.264,0.426l-0.7,2.104l-0.7,-2.104c-0.054,-0.161 -0.144,-0.306 -0.264,-0.426c-0.119,-0.119 -0.265,-0.209 -0.426,-0.262l-2.11,-0.698l2.11,-0.699c0.161,-0.053 0.307,-0.143 0.426,-0.262c0.12,-0.12 0.21,-0.265 0.264,-0.426l0.7,-2.104Z" />
    </svg>
  );
}
